<template>
<div class='weixin-put'>
  
   <router-view></router-view>
</div>
</template>

<script>

export default {
data() {
return {

};
},
watch:{
},
mounted() {

},
methods: {

},
components: {},
}
</script>
<style lang='scss' scoped>
</style>